import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import { getStaff, deleteAll } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddStaff from "./addStaff";
import EditStaff from "./editStaff";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../../../extraComponents/MoreActions";
import { double_to_string } from "../../../../../../constants";


const { Text } = Typography;

export default function Staff() {
  const [staff, setStaff] = useState([]);
  const [addStaff, setAddStaff] = useState(false);
  const [editStaff, setEditStaff] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const staff_ = await getStaff((page - 1) * 10);
    if (staff_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setStaff(staff_?.list ? staff_?.list : []);
    setCount(staff_?.count ? staff_?.count : 0);
    setLoad(false);
  }, [page, updateData]);


  console.log(staff);

  return (
    <>
      <AddStaff
        open={addStaff}
        setOpen={setAddStaff}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />
      <EditStaff
        staffItem={editStaff}
        setEstate={setEditStaff}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
          >
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Name
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              ID
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Employee since
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Drivers Licenses
            </Col>
            <Col xs={8} style={{ display: "flex", justifyContent: "center" }}>
              <Text>
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {staff?.length === 0 ? (
            <Empty description="Keine MitarbeiterInnen vorhanden!" />
          ) : null}

          {staff?.map((staffItem) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditStaff(staffItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {staffItem?.cfeld1}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditStaff(staffItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {staffItem?.cfeld2}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditStaff(staffItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {staffItem?.nfeld1 ? double_to_string(staffItem?.nfeld1) : null}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditStaff(staffItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {staffItem?.cfeld6}
                  </Text>
                </Col>

                {/* <Col
                  xs={12}
                  sm={4}
                  xl={2}
                  style={{
                    borderRight: "1px solid #eaeaea",
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMainPage(2);
                      setMainStates({
                        ...mainStates,
                        staffItem: staffItem?.stkey,
                        staffItemName: staffItem?.cfeld1,
                      });
                    }}
                  >
                    <IntlMessages id="edit" />
                  </Text>
                </Col> */}
                <Col
                  xs={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MoreActions state={staffItem} onDelete={deleteAll}  update={updateData}
        setUpdate={setUpdateData} />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddStaff(!addStaff);
              }}
            >
              <IntlMessages id="workorder.staffItem.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
