import Typography from "antd/lib/typography/Typography";
import { Button, Card, Select } from "antd";
import {
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  message,
  Menu,
  Dropdown,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
  addOrder_baseData,
  editOrder_baseData,
  getCostCenter,
} from "./network_Request";
import {
  string_to_doubleDT,
  doubleDT_to_string,
} from "../../../../../../constants";
import IntlMessages from "../../../../../../util/IntlMessages";
import StaffForOrder from "./staffForOrder";
import VehiclesForOrder from "./vehiclesForOrder";
import MaterialForOrder from "./materialForOrder";
import { useHistory } from "react-router-dom";

const success = () => {
  message.success("Der Auftrag wurde gespeichert!");
};

const error = () => {
  message.error("Ein Fehler ist passiert - bitte nochmal versuchen.");
};

const successEdit = () => {
  message.success("Der Auftrag wurde bearbeitet!");
};

const errorEdit = () => {
  message.error(
    "Ein Fehler ist passiert - bitte nochmal versuchen zu bearbeiten."
  );
};

export default function BaseDataForm(props) {
  const [form] = Form.useForm();
  console.log(props.editOrder);

  const { Option } = Select;
  const { Text } = Typography;

  const [nfeld3, setnfeld3] = useState(
    props?.editOrder?.nfeld3
      ? moment(doubleDT_to_string(props.editOrder.nfeld3), "DD.MM.YYYY HH:mm")
      : null
  );

  const [nfeld5, setnfeld5] = useState(
    props?.editOrder?.nfeld5
      ? moment(doubleDT_to_string(props.editOrder.nfeld5), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld6, setnfeld6] = useState(
    props?.editOrder?.nfeld6
      ? moment(doubleDT_to_string(props.editOrder.nfeld6), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld7, setnfeld7] = useState(
    props?.editOrder?.nfeld7
      ? moment(doubleDT_to_string(props.editOrder.nfeld7), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld8, setnfeld8] = useState(
    props?.editOrder?.nfeld8
      ? moment(doubleDT_to_string(props.editOrder.nfeld8), "DD.MM.YYYY HH:mm")
      : null
  );

  const [costCenter, setCostCenter] = useState(null);
  const [costCenterList, setCostCenterList] = useState(null);

  useEffect(async () => {
    const fetchedCostCenter = await getCostCenter("0");
    /*{console.log('fetchedCostCenter:', fetchedCostCenter)}*/
    setCostCenterList(fetchedCostCenter);
  }, []);

  const costCenter_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCostCenter(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {costCenterList?.map((field) => {
        /*{console.log('cfeld1:', field?.cfeld1)}*/
        return <Menu.Item key={field?.cfeld1}>{field?.cfeld1}</Menu.Item>;
      })}
    </Menu>
  );

  console.log(nfeld5, nfeld6, nfeld7, nfeld8);

  useEffect(() => {
    setnfeld3(
      props?.editOrder?.nfeld3
        ? moment(doubleDT_to_string(props.editOrder.nfeld3), "DD.MM.YYYY HH:mm")
        : moment(new Date(), "DD.MM.YYYY HH:mm")
    );

    setnfeld5(
      props?.editOrder?.nfeld5
        ? moment(doubleDT_to_string(props.editOrder.nfeld5), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld6(
      props?.editOrder?.nfeld6
        ? moment(doubleDT_to_string(props.editOrder.nfeld6), "DD.MM.YYYY HH:mm")
        : null
    );

    setnfeld7(
      props?.editOrder?.nfeld7
        ? moment(doubleDT_to_string(props.editOrder.nfeld7), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld8(
      props?.editOrder?.nfeld8
        ? moment(doubleDT_to_string(props.editOrder.nfeld8), "DD.MM.YYYY HH:mm")
        : null
    );
    setCostCenter(props?.editOrder?.cfeld5 ? props?.editOrder?.cfeld5 : null);
  }, [props.editOrder]);

  const ref = useRef();
  const [stKey, setStKey] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (stKey) {
      history.push(`/main/baseData-Order?editOrderKey=${stKey}`);
    }
  }, [stKey]);

  useEffect(() => ref.current.resetFields(), [props.editOrder]);

  return (
    <>
      <Card
        style={{
          borderRadius: "8px",
          margin: "20px 70px 20px 60px",
          padding: 0,
          height: "auto",
          // width: "100%",
        }}
      >
        <Typography.Text>
          <IntlMessages id="workorder.workorder_large" />
        </Typography.Text>
        <Form
          name="add staff"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            props.editOrder || { cfeld4: localStorage.getItem("uname") }
          }
          style={{
            padding: "0px 30px 0px 70px",
          }}
          onFinish={async (values) => {
            if (!props.editOrder) {
              const response = await addOrder_baseData({
                ...values,
                cfeld5: costCenter,
                nfeld3: nfeld3 ? string_to_doubleDT(nfeld3) : 0,
                nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
                nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
                nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
                nfeld8: nfeld8 ? string_to_doubleDT(nfeld8) : 0,
              });
              if (response?.success) {
                setStKey(response.success);
                success();
              } else {
                error();
              }
            } else {
              const stkey = props.editOrder.stkey;
              const response = await editOrder_baseData({
                ...values,
                stkey,
                cfeld5: costCenter,
                nfeld3: nfeld3 ? string_to_doubleDT(nfeld3) : 0,
                nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
                nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
                nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
                nfeld8: nfeld8 ? string_to_doubleDT(nfeld8) : 0,
              });
              if (response?.success) {
                successEdit();
              } else {
                errorEdit();
              }
            }
            // props.setUpdate(!props.update);
            // setnfeld3(moment(moment(), "DD.MM.YYYY"));
            // setOpen(!open);
          }}
          //     // onFinishFailed={onFinishFailed}
          //     autoComplete="off"
        >
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item
                name="cfeld1"
                label={<IntlMessages id="workorder.nameTitle" />}
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={8}>
              <Form.Item
                name="cfeld2"
                label={<IntlMessages id="workorder.place" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={1} />
            <Col xs={2}>
              <Form.Item
                name="nfeld4"
                type="number"
                label={<IntlMessages id="No." />}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    pattern: /^(\d+)?(\.\d{0,1})?$/, // Pattern for numbers up to one decimal place
                    message:
                      "Bitte eine Zahl mit maximal einer Nachkommastelle eingeben!",
                  },
                ]}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", height: "95px" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld3"
                label={<IntlMessages id="workorder.description" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>


          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item
                name="cfeld5"
                label={<IntlMessages id="workorder.costcenter" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Dropdown overlay={costCenter_menu} trigger="click">
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "36px",
                      borderRadius: "6px",
                      margin: "0px",
                    }}
                  >
                    <Col
                      span={21}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text>{costCenter}</Text>
                      {console.log("costCenter:", costCenter)}
                    </Col>
                    <Col
                      span={3}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DownOutlined style={{ color: "#d9d9d9" }} />
                    </Col>
                  </Row>
                </Dropdown>
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={5}>
              <Form.Item
                name="cfeld4"
                label={<IntlMessages id="workorder.creator" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={1} />
            <Col xs={5}>
              <Form.Item
                label={<IntlMessages id="workorder.creationdatetime" />}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld3}
                  disabled
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  onChange={(e, d) => {
                    setnfeld3(d);
                  }}
                />
              </Form.Item>
            </Col>
            {/*<Col xs={11}>
              <Form.Item
                name="nfeld4"
                type="number"
                label={<IntlMessages id="workorder.plannedduration" />}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    pattern: /^(\d+)?(\.\d{0,1})?$/, // Pattern for numbers up to one decimal place
                    message:
                      "Bitte eine Zahl mit maximal einer Nachkommastelle eingeben!",
                  },
                ]}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>*/}
          </Row>

          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.workstartfrom" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld7}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  onChange={(e, d) => {
                    setnfeld7(e);
                    setnfeld8(e ? moment(e, "DD.MM.YYYY HH:mm") : null);
                    form.setFieldsValue({
                      nfeld8: e ? moment(e, "DD.MM.YYYY HH:mm") : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.workendat" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld8}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  onChange={(e, d) => {
                    setnfeld8(e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld6"
                label={<IntlMessages id="workorder.internalcomment" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.billablefrom" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld5}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={30}
                  onChange={(e, d) => {
                    setnfeld5(e);
                    setnfeld6(e ? moment(e, "DD.MM.YYYY HH:mm") : null);
                    form.setFieldsValue({
                      nfeld6: e ? moment(e, "DD.MM.YYYY HH:mm") : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={2} />
            <Col xs={11}>
              <Form.Item label={<IntlMessages id="workorder.billabletill" />}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={nfeld6}
                  showTime={"HH:mm"}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={30}
                  onChange={(e, d) => {
                    setnfeld6(e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld7"
                label={<IntlMessages id="workorder.commentonbill" />}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              height: "75px",
              marginTop: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{ padding: "0px 60px" }}
              onClick={() => {
                // setOpen(!open);
                ref?.current?.submit();
              }}
            >
              <IntlMessages id="save" />
            </Button>
          </Row>
        </Form>
      </Card>

      <StaffForOrder orderStaff={props.editOrder} />

      <VehiclesForOrder orderVehicle={props.editOrder} />

      <MaterialForOrder orderMaterial={props.editOrder} />
    </>
  );
}
