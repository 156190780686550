import {
    Modal,
    Input,
    Form,
    Row,
    Col,
    DatePicker,
    Typography,
    message,
  } from "antd";
  import { double_to_string, string_to_double } from "../../../../../../constants";
  import { useEffect, useRef, useState } from "react";
  import moment from "moment";
  import IntlMessages from "util/IntlMessages";
import { editVehicle } from "./network_requests";
  
  const success = () => {
    message.success("Speichern erfolgreich!");
  };
  
  const error = () => {
    message.error("Error happened, please try again.");
  };
  
  export default function EditVehicle(props) {
    const { vehicleItem, setEstate} = props;
  
    const [nfeld1, setnfeld1] = useState(
      vehicleItem?.nfeld1 ? moment(double_to_string(vehicleItem?.nfeld1), "DD.MM.YYYY") : null
    );
  
 
    
    useEffect(() => {
      setnfeld1(
        vehicleItem?.nfeld1 ? moment(double_to_string(vehicleItem?.nfeld1), "DD.MM.YYYY") : null
      );
  
     
    }, [vehicleItem]);
  
    const ref = useRef();
    const { Text } = Typography;

    const commatoPointParser = (value) => {
      // Convert commas to dots and parse as float
      return parseFloat(value.replace(/,/g, '.'));
    };
  
    return (
      <Modal
        title={"Fahrzeug bearbeiten"}
        visible={Boolean(vehicleItem)}
        centered
        destroyOnClose
        okText={<IntlMessages id="save" />}
        onOk={() => {
          // setOpen(!open);
          ref?.current?.submit();
        }}
        onCancel={() => {
          setEstate(null);
        }}
        style={{
          minWidth: "58vw",
        }}
      >
        <Form
          name="edit Vehicle"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            ...vehicleItem,
          }}
          style={{
            paddingLeft:"20px"
          }}
          onFinish={async (values) => {
            const response = await editVehicle({
              ...vehicleItem,
              ...values,
              nfeld1: nfeld1 ? string_to_double(nfeld1) : 0,
              nfeld2: commatoPointParser(values.nfeld2),
            });
            if (response?.success) {
              success();
            } else {
              error();
            }
            props.setUpdate(!props.update);
            setEstate(null);
          }}
          //   onFinishFailed={onFinishFailed}
          //   autoComplete="off"
        >
         <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={8}>
              <Form.Item
                name="cfeld1"
                label="Name/Type"
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={1} /> */}
            <Col xs={8}>
              <Form.Item
                name="cfeld2"
                label="Kennzeichen"
                style={{ marginBottom: 0 ,marginLeft:"10px" }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={1} /> */}
            <Col xs={8}>
              <Form.Item
                 name="nfeld2"
                label="Preis je Stunde (€)"
                style={{ marginBottom: 0 ,marginLeft:"10px"}}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={8}>
            <Form.Item
                name="cfeld3"
                label="Zustand"
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={1} /> */}
            <Col xs={8}>
              <Form.Item
                name="cfeld4"
                label="Kostenstelle"
                style={{ marginBottom: 0,marginLeft:"10px" }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={1} /> */}
            <Col xs={8}>
              <Form.Item
                 name="cfeld5"
                label="Inventar No"
                style={{ marginBottom: 0,marginLeft:"10px" }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={8}>
            <Form.Item label="Erstzulassung">
                <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={nfeld1}
                  format="DD.MM.YYYY"
                  onChange={(e, d) => {
                    setnfeld1(d);
                  }}
                />
              
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item name="cfeld6" label="Kommentar">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        
         
        </Form>
      </Modal>
    );
  }
  