import { url, string_to_double } from "../../../../../../constants";
import axios from "axios";
import moment from "moment";

export const getOrder = async (limitFrom, filter, dates) => {
  console.log(limitFrom);
  try {

    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getWorkOrdersWithSumNFELD10`,
      {
        group: "class_g33",
        stClass: "1",
        // stKey: "47D68CEA8CA340",
        // detail: "cldetail_g33",
        // detClass: "2",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields:
          filter === "created"
            ? [
                {
                  fieldNo: "nfeld3",
                  fromVal: `${dates[0]}0000`,
                  opVal: "between",
                  toVal: `${dates[1]}2400`,
                },
              ]
            : filter === "planned"
            ? [
                {
                  fieldNo: "nfeld7",
                  fromVal: `${dates[0]}0000`,
                  opVal: "between",
                  toVal: `${dates[1]}2400`,
                },
              ]
            : [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getComments = async (key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getLogbookForObject`,
      {
        stKey: key,
        limitFrom: "0",
        limitTo: "10",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addComment = async (values, key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}insertLogbookEntryForOrder`,
      {
        ...values,
        detTable__: "cldetail_g24",
        detkey: "",
        active: 1,
        stkey: key,
        stclass: 1,
        detclass: 1,
        gisid: null,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addOrder_baseData = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}orderInsertBaseData`,
      {
        active: 1,
        stclass: 1,
        group: 33,
        stkey: "",
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editOrder_baseData = async (values, stkey) => {
  console.log(values);
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: 33,
        active: 1,
        stkey: stkey,
        stclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllStaff = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "2",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const AssignStaffForOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const modifiedValues = values.map((value) => {
      return { ...value, nfeld10: 1.0 };
    });
    const response = await axios.post(
      `${url}orderUpdateStaff`,

      [...modifiedValues],

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const DeAssignStaffForOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const modifiedValues = values.map((value) => {
      return { ...value, nfeld10: 0.0 };
    });
    const response = await axios.post(
      `${url}orderUpdateStaff`,

      [...modifiedValues],

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getStaffForOrder = async (stKey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: stKey,
        detail: "cldetail_g33",
        detClass: "5",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

//vehicle for all
export const AssignVehicleForOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const modifiedValues = values.map((value) => {
      return { ...value, nfeld10: 1.0 };
    });
    const response = await axios.post(
      `${url}orderUpdateVehicles`,

      [...modifiedValues],

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const DeAssignVehicleForOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const modifiedValues = values.map((value) => {
      return { ...value, nfeld10: 0.0 };
    });
    const response = await axios.post(
      `${url}orderUpdateVehicles`,

      [...modifiedValues],

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getVehicleForOrder = async (stKey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: stKey,
        detail: "cldetail_g33",
        detClass: "4",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getMaterialForOrder = async (stKey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: stKey,
        detail: "cldetail_g33",
        detClass: "6",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addMaterial = async (values, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    // const { stkey, ...restValues } = values;
    const response = await axios.post(
      `${url}commonInsertDetail`,

      {
        detTable__: "cldetail_g33",
        detkey: "",
        active: 1,
        stkey: stkey,
        stclass: 1,
        detclass: 6,
        gisid: " ",
        nfeld10: 1,
        ...values,
      },

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editMaterialForOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const { detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateDetail`,

      {
        detTable__: "cldetail_g33",
        detkey: "",
        active: 1,
        stkey: "",
        stclass: 1,
        detclass: 6,
        gisid: " ",
        ...restValues,
      },

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeMaterial = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const { active, detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateDetail`,

      {
        detTable__: "cldetail_g33",
        detkey: "47e02bcf563848",
        active: 0,
        stclass: 1,
        detclass: 6,
        gisid: " ",
        ...restValues,
      },

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFiles = async (key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}requestFileList`,
      {
        attribute: attribute,
        objID: key,
        group: "33",
        stClass: "1",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const downloadFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadFileMIMO`,
      {
        imageName: filename,
        objID: key,
        detclass: 0,
        stclass: 1,
        group: 33,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadFile = async (file, key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const date = moment(moment()).format("DD.MM.YYYY");

    const time = moment(moment()).format("HH:mm");

    var a = time.substr(0, 2);
    var b = time.substr(3, 2);

    const response = await axios.post(
      `${url}uploadFileWithMIMO`,
      {
        file: file?.file,
        filename: file?.filename,
        attribute: attribute,
        detclass: 0,
        group: 33,
        stclass: 1,
        date: string_to_double(date),
        time: parseFloat(a + b),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: key,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}removeFile`,
      {
        stkey: key,
        cfeld1: filename,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCostCenter = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getCostCenterListForOrder`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "3",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const wordInternalBillForOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}wordInternalBillForOrder`,
      {
        group: "1",
        stclass: 4,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const wordAuftragForOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}wordAuftragForOrder`,
      {
        group: "1",
        stclass: 4,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeOrder = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: "33",
        ...values,
        active: 0,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
