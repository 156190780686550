import {
  Typography,
  Menu,
  Dropdown,
  Modal,
  Row,
  Col,
  Popconfirm,
  Popover,
  Button,
  Upload,
  message,
} from "antd";
import IntlMessages from "util/IntlMessages";
import { useEffect, useState } from "react";
import {
  getPictures,
  getPicture,
  uploadImage,
  deleteImage,
} from "./network_requests";
import { DownOutlined } from "@ant-design/icons";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { string_to_double } from "../../../constants";
import moment from "moment";

export default function MoreTreeActions(props) {
  const { state, setEdit, edit, group } = props;
  const { Text } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [image, setImage] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [open, setOpen] = useState(null);
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(false);

  const uploadProps = {
    accept: ".png",
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          setFile(e.target.result);
        };
        reader.readAsDataURL(file.originFileObj);
      });
    },
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setEdit(state);
        }}
      >
        Details
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        Pictures
      </Menu.Item>
      <Popconfirm
        placement="bottomLeft"
        title={`Do you really want to ${
          props.tree ? "fall this tree" : "remove this object?"
        }`}
        onConfirm={async () => {
          if (props.tree) {
            edit({
              ...state,
              cfeld28: string_to_double(moment().format("DD.MM.YYYY")),
            });
          }
        }}
        okText="Yes"
        cancelText="No"
      >
        <Menu.Item key="2">Remove Object</Menu.Item>
      </Popconfirm>
    </Menu>
  );

  useEffect(async () => {
    if (file) {
      const upload = await uploadImage({
        file: file,
        objID: state?.stkey,
        stclass: state?.stclass,
        group: group ? group : 3,
      });
      setFile(null);
      if (upload?.success === "saved") {
        message.success("Image Uploaded Successfully");
        setImage(null);
        setIsModalVisible(!isModalVisible);
        setUpdate(!update);
      } else {
        message.error("An error occurred while performing action");
      }

      setIsModalVisible(!isModalVisible);
    }
  }, [file]);

  useEffect(async () => {
    const pictures_ = await getPictures({
      attribute: "none",
      objID: state?.stkey,
      group: group ? group : "3",
      stClass: "2",
    });
    setPictures(pictures_);
  }, [state, update]);

  useEffect(async () => {
    if (photo) {
      setIsModalVisible(!isModalVisible);
      const picture_ = await getPicture({
        imageName: photo?.cfeld1,
        objID: photo?.gisid,
        attribute: "none",
        stclass: photo?.stclass,
        group: group ? group : 3,
      });
      setImage(picture_);
    }
  }, [photo]);

  return (
    <>
      <Modal
        title={`Photo ${state?.cfeld1}`}
        visible={Boolean(photo)}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
      >
        <img
          src={`data:image/jpeg;base64,${image?.file}`}
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>
      {cameraOpen ? (
        <Camera
          isImageMirror={false}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ height: 303, width: 290 }}
          onTakePhoto={(dataUri) => {
            // dispatch(setCamera({ ...camera, isOpen: false }));
            setFile(dataUri);
            setCameraOpen(!cameraOpen);
            setIsModalVisible(!isModalVisible);
          }}
        />
      ) : null}

      <Modal
        title={`Photo ${state?.cfeld1}`}
        visible={isModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        <Row
          style={{
            backgroundColor: "#FAFAFA",
            borderBottom: "1px solid #eaeaea",
            fontWeight: 500,
            padding: "10px 30px",
            margin: "20px 0 0 0",
          }}
        >
          <Col
            xs={12}
            sm={12}
            xl={18}
            style={{
              padding: 0,
            }}
          >
            <Text>Name</Text>
          </Col>
          <Col xs={12} sm={12} xl={6}>
            <Text>
              {" "}
              <IntlMessages id="actions" />
            </Text>
          </Col>
        </Row>
        {pictures?.map((picture) => {
          return (
            <Row
              style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #eaeaea",
                padding: "10px 30px",
                margin: "0",
              }}
            >
              <Col
                xs={12}
                sm={12}
                xl={18}
                style={{
                  padding: 0,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPhoto(picture);
                }}
              >
                <Text
                  style={{
                    color: "#038fde",
                  }}
                >
                  {picture?.cfeld1}
                </Text>
              </Col>

              <Col>
                <Text
                  style={{
                    color: "#038fde",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpen(picture);
                  }}
                >
                  <IntlMessages id="more_actions" />
                  {picture?.stkey === open?.stkey ? (
                    <Popover
                      content={
                        <Popconfirm
                          placement="bottomLeft"
                          title={"Do you really want to delete the Picture?"}
                          onConfirm={async () => {
                            const del = await deleteImage({
                              stkey: picture?.stkey,
                              cfeld1: picture?.cfeld1,
                            });
                            if (del?.success === "deleted") {
                              message.success("Image deleted Successfully");
                            } else {
                              message.error(
                                "An error occurred while performing action"
                              );
                            }

                            setOpen(null);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Text
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Delete
                          </Text>
                        </Popconfirm>
                      }
                      trigger="click"
                      visible={open}
                      onVisibleChange={() => {
                        setOpen(!open);
                      }}
                    ></Popover>
                  ) : null}
                </Text>
                <DownOutlined
                  size="small"
                  style={{
                    color: "#038fde",
                    paddingLeft: "10px",
                    fontSize: "10px",
                  }}
                />
              </Col>
            </Row>
          );
        })}
        <Row
          style={{
            backgroundColor: "#FAFAFA",
            borderBottom: "1px solid #eaeaea",
            fontWeight: 500,
            padding: "10px 30px",
            margin: "0",
          }}
          justify="end"
        >
          <Upload {...uploadProps}>
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {}}
            >
              <IntlMessages id="add_from_files" />
            </Button>
          </Upload>
          <Button
            size="small"
            type="primary"
            style={{
              margin: "0 0 0 15px",
            }}
            onClick={() => {
              setCameraOpen(!cameraOpen);
              setIsModalVisible(!isModalVisible);
            }}
          >
            <IntlMessages id="add_from_camera" />
          </Button>
        </Row>
      </Modal>

      <Dropdown overlay={menu} trigger={["click"]}>
        <Text
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
        >
          <IntlMessages id="more" />
        </Text>
      </Dropdown>
    </>
  );
}
