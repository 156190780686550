import { Row, Typography, Col, Button, Pagination, Spin, Select } from "antd";
import { deleteTask, getFields, getTasks } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import in_progress from "../../../assets/Icons_waste/task_inprogress.png";
import taskOpen from "../../../assets/Icons_waste/task_open.png";
import planned from "../../../assets/Icons_waste/task_planned.png";
import finished from "../../../assets/Icons_waste/task_finished.png";
import AddTask from "./addTask";
import EditTask from "./editTask";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { doubleDT_to_string, double_to_string } from "../../../constants";
import MoreActions from "../../extraComponents/MoreActions";

const { Text } = Typography;

export default function Task(props) {
  const [Task, setTask] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [updateData, setUpdateData] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [persons, setPersons] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    setLoad(true);
    const Task_ = await getTasks((page - 1) * 10, filter);

    if (Task_?.status === 401) {
      localStorage.removeItem("task_id");
      history.go(0);
    }

    const assignments_ = await getFields("G01C04D00F13");
    const persons_ = await getFields("G20C01D00F05");
    setAssignments(assignments_);
    setPersons(persons_);
    setTask(Task_ ? Task_?.list : []);
    setCount(Task_?.count ? Task_?.count : 0);
    setLoad(false);
  }, [page, updateData, filter]);

  return (
    <>
      <AddTask
        open={addTask}
        setOpen={setAddTask}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        persons={persons}
        assignments={assignments}
      />
      <EditTask
        task={editTask}
        setTask={setEditTask}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        persons={persons}
        assignments={assignments}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <Row
            style={{
              borderBottom: "1px solid #eaeaea",
              fontWeight: 400,
              padding: "20px",
              margin: "0",
              width: "100%",
            }}
          >
            <Col xs={16}>
              <Text style={{ fontSize: "18px" }}>
                <IntlMessages id="tasks.tasks" />
              </Text>
            </Col>
            <Col xs={5}>
              <Select
                defaultValue={filter}
                size="small"
                style={{
                  width: "100%",
                }}
                onChange={(value) => {
                  setFilter(value);
                }}
                options={[
                  {
                    value: "all",
                    label: "All",
                  },
                  {
                    value: "planned",
                    label: "Planned",
                  },
                  {
                    value: "inProgress",
                    label: "In Progress",
                  },
                  {
                    value: "finished",
                    label: "Finished",
                  },
                ]}
              />
            </Col>
            <Col
              xs={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setAddTask(!addTask);
                }}
              >
                <IntlMessages id="tasks.addtask" />
              </Button>
            </Col>
          </Row>
          <div style={{ padding: "40px 30px", fontWeight: 400 }}>
            <Row
              style={{
                width: "100%",
                padding: "10px 0px 10px 15px",
                backgroundColor: "#FAFAFA",
                marginLeft: "0",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 400,
              }}
            >
              <Col
                xs={2}
                style={{
                  padding: 0,
                }}
              >
                <Text>Status</Text>
              </Col>
              <Col
                xs={7}
                style={{
                  padding: 0,
                }}
              >
                <Text>
                  <IntlMessages id="tasks.title" />
                </Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text>
                  <IntlMessages id="tasks.Created" />
                </Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text>
                  <IntlMessages id="tasks.Planned" />
                </Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text>
                  <IntlMessages id="tasks.InProgress" />
                </Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text>
                  <IntlMessages id="tasks.Finished" />
                </Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              ></Col>
            </Row>

            {Task?.map((task) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 0px 10px 15px",
                    margin: "0",
                    fontWeight: 400,
                  }}
                >
                  <Col
                    xs={2}
                    style={{
                      padding: 12,
                    }}
                  >
                    <img
                      src={
                        task?.nfeld7
                          ? finished
                          : task?.nfeld5
                          ? in_progress
                          : task?.nfeld6
                          ? planned
                          : taskOpen
                      }
                      style={{ width: "18px" }}
                    />
                  </Col>
                  <Col
                    xs={7}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditTask(task);
                    }}
                  >
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {task?.cfeld1}
                    </Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text>
                      {task?.nfeld3 ? doubleDT_to_string(task?.nfeld3) : "-"}
                      <br />
                      {task?.nfeld3 ? task?.cfeld3 : ""}
                    </Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text>
                      {task?.nfeld6 ? doubleDT_to_string(task?.nfeld6) : "-"}{" "}
                      <br />
                      {task?.nfeld6 ? task?.cfeld5 : ""}
                    </Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text>
                      {task?.nfeld5 ? doubleDT_to_string(task?.nfeld5) : "-"}
                    </Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text>
                      {task?.nfeld7 ? doubleDT_to_string(task?.nfeld7) : "-"}
                    </Text>
                  </Col>
                  <Col xs={3}>
                    <MoreActions
                      state={task}
                      onDelete={deleteTask}
                      update={updateData}
                      setUpdate={setUpdateData}
                    />
                    <DownOutlined
                      size="small"
                      style={{
                        color: "#038fde",
                        paddingLeft: "10px",
                        fontSize: "10px",
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row
              style={{
                padding: "10px 30px 0 30px",
                backgroundColor: "#fafafa",
                borderBottom: "1px solid #eaeaea",
                margin: "0",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                defaultPageSize={10}
                style={{
                  margin: 0,
                }}
                onChange={(newpage) => {
                  setPage(newpage);
                }}
              />
            </Row>
          </div>
        </div>
      ) : null}
    </>
  );
}
