import {
    Modal,
    Input,
    Form,
    Row,
    Col,
    DatePicker,
    Typography,
    message,
  } from "antd";
  import { double_to_string , string_to_double} from "../../../../../../constants";
  import { useEffect, useRef, useState } from "react";
  import moment from "moment";

//   import { editArtist } from "../networkrequests";
import { editMaterial, editMaterialForOrder } from "./network_Request";
  import IntlMessages from "util/IntlMessages";

  const success = () => {
    message.success("The Material has been updated.");
  };
  
  const error = () => {
    message.error("Error happened, please try again.");
  };

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    return parseFloat(value.replace(/,/g, '.'));
  };
  
  export default function EditMaterialForOrder(props) {
    
    const { editMaterial,setEstate} = props;
    const open = Boolean(editMaterial);
    console.log(open)
  
  

    const ref = useRef();
    const { Text } = Typography;
  
    return (
      <Modal
        title={"Update Material For This Order"}
        visible={open}
        centered
        destroyOnClose
        okText={<IntlMessages id="save" />}
        onOk={() => {
          // setOpen(!open);
          ref?.current?.submit();
        }}
        onCancel={() => {
          setEstate(null);
        }}
        style={{
          minWidth: "58vw",
        }}
      >
        <Form
          name="editMaterial"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            ...editMaterial,
          }}
          style={{
            paddingLeft:"20px"
          }}
          onFinish={async (values) => {
            console.log(values)
            props.setLoad(true);
            const response = await editMaterialForOrder({
              ...editMaterial,
              ...values,
              nfeld2: commatoPointParser(values.nfeld2),
            });
            if (response?.success) {
              success();
            } else {
              error();
            }
            props.setUpdate(!props.update);
            setEstate(null);
          }}
         
            autoComplete="off"
        >
        

          <Row width={{width:"100%", height:"75px"}}>
                <Col xs={8}>
                <Form.Item
                 name="cfeld1">
                <Input 
                 placeholder="Another material"
                 style={{ marginTop: "20px" }}
                 />
               </Form.Item>
                </Col>
                <Col xs={1}/>
                <Col  xs={8}>
                <Form.Item
                 name="nfeld1">
                <Input 
                 placeholder="Another preis"
                 style={{ marginTop: "20px" }}/>
                  </Form.Item>
                </Col>
                </Row>
         
        </Form>
      </Modal>
    );
  }
  