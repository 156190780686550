import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import { getEstates } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddEstates from "./addEstates";
import EditEstates from "./editEstates";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../../../extraComponents/MoreActions";

const { Text } = Typography;

export default function Estates(props) {
  const { setMainPage, mainStates, setMainStates } = props;
  const [estates, setEstates] = useState([]);
  const [addEstates, setAddEstates] = useState(false);
  const [editEstates, setEditEstates] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const estates_ = await getEstates((page - 1) * 10);
    if (estates_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setEstates(estates_?.list ? estates_?.list : []);
    setCount(estates_?.count ? estates_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  return (
    <>
      <AddEstates
        open={addEstates}
        setOpen={setAddEstates}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />
      <EditEstates
        estate={editEstates}
        setEstate={setEditEstates}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <IntlMessages id="estate.title" />
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="estate.title" />
              </Text>
            </Col>
            <Col xs={12} sm={12} xl={6}>
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {estates?.length === 0 ? <Empty description="Keine Gruppen / Liegenschaften vorhanden!"/> : null}

          {estates?.map((estate) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditEstates(estate);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {estate?.cfeld1}
                    
                  </Text>
                
                  
                </Col>
                
                {/* <Col
                  xs={12}
                  sm={4}
                  xl={2}
                  style={{
                    borderRight: "1px solid #eaeaea",
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMainPage(2);
                      setMainStates({
                        ...mainStates,
                        estate: estate?.stkey,
                        estateName: estate?.cfeld1,
                      });
                    }}
                  >
                    <IntlMessages id="edit" />
                  </Text>
                </Col> */}
                <Col>
                  <MoreActions state={estate} />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <RightSquareOutlined
                    size="large"
                    style={{
                      color: "#038fde",
                      marginLeft: "40px",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      setMainPage(2);
                      setMainStates({
                        ...mainStates,
                        estate: estate?.stkey,
                        estateName: estate?.cfeld1,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddEstates(!addEstates);
              }}
            >
              <IntlMessages id="estate.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
