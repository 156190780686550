import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Dropdown,
  Typography,
  Menu,
  InputNumber,
  DatePicker,
  Button,
} from "antd";
import { useRef, useState, useEffect } from "react";
import { editTask } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import { doubleDT_to_string, string_to_doubleDT } from "../../../constants";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import MoreActions from "../../extraComponents/MoreActions";
import SignTask from "./signTask";

export default function EditTask(props) {
  const { task, setTask, assignments, persons } = props;

  const open = Boolean(task);
  const [assignment, setAssignment] = useState(
    task?.cfeld4 ? task?.cfeld4 : null
  );
  const [person, setPerson] = useState(task?.cfeld5 ? task?.cfeld5 : null);
  const [nfeld5, setnfeld5] = useState(
    task?.nfeld5
      ? moment(doubleDT_to_string(task?.nfeld5), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld6, setnfeld6] = useState(
    task?.nfeld6
      ? moment(doubleDT_to_string(task?.nfeld6), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld7, setnfeld7] = useState(
    task?.nfeld7
      ? moment(doubleDT_to_string(task?.nfeld7), "DD.MM.YYYY HH:mm")
      : null
  );
  const [signTask, setSignTask] = useState(false);
  const { Text } = Typography;
  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    setAssignment(task?.cfeld4 ? task?.cfeld4 : null);
    setPerson(task?.cfeld5 ? task?.cfeld5 : null);
    setnfeld5(
      task?.nfeld5
        ? moment(doubleDT_to_string(task?.nfeld5), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld6(
      task?.nfeld6
        ? moment(doubleDT_to_string(task?.nfeld6), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld7(
      task?.nfeld7
        ? moment(doubleDT_to_string(task?.nfeld7), "DD.MM.YYYY HH:mm")
        : null
    );
  }, [task]);

  const assignment_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setAssignment(e.key);
      }}
    >
      {assignments?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const person_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setPerson(e.key);
      }}
    >
      {persons?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <>
      {signTask ? (
        <SignTask open={signTask} setOpen={setSignTask} stkey={task?.stkey} />
      ) : (
        <>
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "#038fde",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setTask(null);
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                />
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                Edit
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#f3f7ff",
                left: 0,
                padding: "20px",
                width: "100%",
                color: "gray",
                textTransform: "uppercase",
              }}
            >
              edit task
            </div>
          </>
          <Form
            name="edit Task"
            ref={ref}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{ padding: "10px 30px" }}
            initialValues={{
              ...task,
              nfeld5: nfeld5,
              nfeld6: nfeld6,
              nfeld7: nfeld7,
              nfeld3: task
                ? doubleDT_to_string(task?.nfeld3, "DD.MM.YYYY HH:mm")
                : null,
            }}
            onFinish={async (values) => {
              if (values?.cfeld1) {
                setTask(null);
                props.setLoad(true);
                const response = await editTask({
                  ...task,
                  ...values,
                  cfeld4: assignment,
                  cfeld5: person,
                  nfeld3: string_to_doubleDT(values?.nfeld3),
                  nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
                  nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
                  nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
                });

                if (response.status === 401) {
                  localStorage.removeItem("task_id");
                  history.go(0);
                }

                props.setUpdate(!props.update);
              }
            }}
            //   onFinishFailed={onFinishFailed}
            //   autoComplete="off"
          >
            <Form.Item
              label={<IntlMessages id="tasks.title" />}
              name="cfeld1"
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: "Please enter the title!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="tasks.description" />}
              name="cfeld2"
              style={{ marginBottom: 0 }}
            >
              <TextArea rows={6} />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.Creator" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.creationdatetime" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="tasks.assignment" />}
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={assignment_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{assignment}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.executionPerson" />}
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={person_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{person}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.durationh" />}
              name="nfeld4"
              style={{ marginBottom: 0 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.InProgressDateTime" />}
              name="nfeld5"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld5}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld5(d);
                }}
              />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.PlannedDateTime" />}
              name="nfeld6"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld6}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld6(d);
                }}
              />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.FinishedDateTime" />}
              name="nfeld7"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld7}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld7(d);
                }}
              />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="tasks.comment" />}
              name="cfeld6"
              style={{ marginBottom: "15px" }}
            >
              <Input />
            </Form.Item>
            <Form.Item style={{ marginTop: "16px" }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setSignTask(!signTask);
                }}
              >
                Sign
              </Button>
              <Button>
                <MoreActions
                  state={task}
                  onDelete={props?.onDelete}
                  update={props?.update}
                  setUpdate={props?.setUpdate}
                />
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
}
