import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Dropdown,
  Menu,
  Button,
  message
} from "antd";
import { useEffect, useRef, useState } from "react";
//import { addEstates } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { editProperty,duplicateProperty } from "./network_requests";

import { string_to_double, double_to_string } from "../../../../constants";
import { DownOutlined } from "@ant-design/icons";
import { Label } from "recharts";
import './style.css';

const success = () => {
  message.success("Duplicate success!");
};

const error = () => {
  message.error("Duplicate error!");
};



export default function EditProperty(props) {
  const { open, setOpen, setPage, fields } = props;

  const [nfeld1, setnfeld1] = useState(
    open?.nfeld1
      ? moment(double_to_string(open?.nfeld1), "DD.MM.YYYY")
      : ""
  );
  const [nfeld2, setnfeld2] = useState(
    open?.nfeld2
      ? moment(double_to_string(open?.nfeld2), "DD.MM.YYYY")
      : ""
  );

  const [community, setCommunity] = useState(null);
  const [owner, setOwner] = useState(null);
  const [contracttype, setContracttype] = useState(null);

  const history = useHistory();
  const ref = useRef();
  const [form] = Form.useForm();

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    return parseFloat(value.replace(/,/g, '.'));
  };

  const [inputValueCfeld14, setInputValueCfeld14] = useState(open?.cfeld14);
    const handleChange = (event) => {
      setInputValueCfeld14(event.target.value);
    };

  const community_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCommunity(e.key);
      }}
    >
      {fields?.community?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const owner_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setOwner(e.key);
      }}
    >
      {fields?.owner?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const contracttype_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setContracttype(e.key);
      }}
    >
      {fields?.contracttype?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const scrollableMenuStyle = {
    maxHeight: '200px',
    overflow: 'auto',
  };

  useEffect(() => {
    setnfeld1(
      open?.nfeld1
        ? moment(double_to_string(open?.nfeld1), "DD.MM.YYYY")
        : ""
    );
    setnfeld2(
      open?.nfeld2
        ? moment(double_to_string(open?.nfeld2), "DD.MM.YYYY")
        : ""
    );
    setCommunity(open?.cfeld3 ? open?.cfeld3 : null);
    setContracttype(open?.cfeld5 ? open?.cfeld5 : null);
    setOwner(open?.cfeld6 ? open?.cfeld6 : null);
  }, [open]);

  

  return (
    <Modal
      title={"Edit Property"}
      width="920"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={"Save"}
      onOk={() => {
        ref?.current?.submit();
        setOpen(null);
      }}
      onCancel={() => {
        setOpen(null);
      }}
      style={{
        minWidth: "920px",
        maxWidth: "920px",
      }}
      footer={
        <Row>
          <Col xs={4}>
            {
              <Button
              onClick={async () => {
                  const response = await duplicateProperty(open?.stkey);
                  if (response?.success) {
                    success();
                  } else {
                    error();
                  }
                setOpen(null);
              }}
              >
                <IntlMessages id="duplicate" />
              </Button>
            }
          </Col>
          <Col xs={20} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              key="back"
              onClick={() => {
                setOpen(null);
              }}
            >
              <IntlMessages id="cancel" />
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setOpen(null);
                ref?.current?.submit();
              }}
            >
              <IntlMessages id="save" />
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        name="Edit Property"
        ref={ref}
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...open,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editProperty({
            ...open,
            ...values,
            nfeld1: string_to_double(nfeld1),
            nfeld2: string_to_double(nfeld2),
            cfeld3: community,
            cfeld5: contracttype,
            cfeld6: owner,
            cfeld14: inputValueCfeld14,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Tarla"}
              name="cfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Parcella"}
              name="cfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item label={"ha"} name="nfeld3" style={{ marginBottom: 0 }}>
              <Input
                onChange={() => {
                  form.setFieldsValue({
                    cfeld9:
                      (parseFloat(form.getFieldValue("nfeld3"))
                        ? parseFloat(form.getFieldValue("nfeld3"))
                        : 0) *
                      (parseFloat(form.getFieldValue("cfeld8"))
                        ? parseFloat(form.getFieldValue("cfeld8"))
                        : 0),
                  });
                  form.setFieldsValue({
                    cfeld12:
                      (parseFloat(form.getFieldValue("cfeld9"))
                        ? parseFloat(form.getFieldValue("cfeld9"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld10"))
                        ? parseFloat(form.getFieldValue("cfeld10"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld11"))
                        ? parseFloat(form.getFieldValue("cfeld11"))
                        : 0),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Community"}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={community_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography.Text>{community}</Typography.Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
              {/* <Input /> */}
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Land registry number "}
              name="cfeld15"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
                <Form.Item
                  label={"Catastre number"}
                  name="cfeld16"
                  style={{ marginBottom: 0 }}
                >
                  <Input />
                </Form.Item>
              </Col>
        </Row>
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Contract No."}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item label={"Date of Contract"} style={{ marginBottom: 0 }}>
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld2}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld2(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Type of Contract"}
              name="cfeld5"
              style={{ marginBottom: 0 }}
            >
             <Dropdown overlay={contracttype_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography.Text>{contracttype}</Typography.Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
              {/*<Input />*/}
            </Form.Item>
          </Col>
         
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Previous Owner Name"}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={owner_menu} trigger="click" overlayClassName="scrollable-menu">
              <div style={scrollableMenuStyle}>
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography.Text>{owner}</Typography.Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
                </div>
              </Dropdown>

              {/* <Input /> */}
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={"Number of Procura"}
              name="cfeld17"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item label={"Date of Procura"} style={{ marginBottom: 0 }}>
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          
        </Row>

        <Row>
          
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={"RON/ha"}
              name="cfeld8"
              style={{ marginBottom: 0 }}
            >
              <Input
                onChange={() => {
                  form.setFieldsValue({
                    cfeld9:
                    Number((parseFloat(form.getFieldValue("nfeld3"))
                        ? parseFloat(form.getFieldValue("nfeld3"))
                        : 0) *
                      (parseFloat(form.getFieldValue("cfeld8"))
                        ? parseFloat(form.getFieldValue("cfeld8"))
                        : 0)).toFixed(2),
                  });
                  form.setFieldsValue({
                    cfeld12:
                    Number((parseFloat(form.getFieldValue("cfeld9"))
                        ? parseFloat(form.getFieldValue("cfeld9"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld10"))
                        ? parseFloat(form.getFieldValue("cfeld10"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld11"))
                        ? parseFloat(form.getFieldValue("cfeld11"))
                        : 0)).toFixed(2),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item label={"RON"} name="cfeld9" style={{ marginBottom: 0 }}>
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={"RON notary"}
              name="cfeld10"
              style={{ marginBottom: 0 }}
            >
              <Input
                onChange={() => {
                  form.setFieldsValue({
                    cfeld12:
                      Number((parseFloat(form.getFieldValue("cfeld9"))
                        ? parseFloat(form.getFieldValue("cfeld9"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld10"))
                        ? parseFloat(form.getFieldValue("cfeld10"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld11"))
                        ? parseFloat(form.getFieldValue("cfeld11"))
                        : 0)).toFixed(2),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={"RON other"}
              name="cfeld11"
              style={{ marginBottom: 0 }}
            >
              <Input
                onChange={() => {
                  form.setFieldsValue({
                    cfeld12:
                      Number((parseFloat(form.getFieldValue("cfeld9"))
                        ? parseFloat(form.getFieldValue("cfeld9"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld10"))
                        ? parseFloat(form.getFieldValue("cfeld10"))
                        : 0) +
                      (parseFloat(form.getFieldValue("cfeld11"))
                        ? parseFloat(form.getFieldValue("cfeld11"))
                        : 0)).toFixed(2),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={"RON total"}
              name="cfeld12"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={"Comment"} name="cfeld13" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item label={"Filepath"} name="cfeld14" style={{ marginBottom: 0 }}>
          <Input value={inputValueCfeld14} onChange={handleChange} />
          <p style={{margin:5}}>
            <a href={"file:///".concat(inputValueCfeld14)}>{inputValueCfeld14}</a>
          </p>
        </Form.Item>
      </Form>
    </Modal>
  );
}
