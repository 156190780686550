import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import { getFields, getFloors, getMachines } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddFloor from "./addFloor";
import EditFloors from "./editFloor";
import AddMachine from "./addMachine";
import EditMachine from "./editMachine";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../../../extraComponents/MoreActions";

const { Text } = Typography;

export default function Floors(props) {
  const { setMainPage, mainStates, setMainStates } = props;
  const [floors, setFloors] = useState([]);
  const [machines, setMachines] = useState([]);
  const [addFloors, setAddFloors] = useState(false);
  const [editFloors, setEditFloors] = useState(null);
  const [addMachine, setAddMachine] = useState(false);
  const [editMachine, setEditMachine] = useState(null);
  const [count, setCount] = useState(0);
  const [countM, setCountM] = useState(0);
  const [page, setPage] = useState(1);
  const [pageM, setPageM] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [updateDataM, setUpdateDataM] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [fields, setFields] = useState({});

  useEffect(async () => {
    setLoad(true);
    const floors_ = await getFloors((page - 1) * 5, mainStates?.building);

    if (floors_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    const interval = await getFields("G01C08D00F17");
    setFields({
      interval: interval,
    });

    setFloors(floors_?.list ? floors_?.list : []);
    setCount(floors_?.count ? floors_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  useEffect(async () => {
    setLoad(true);
    const machines_ = await getMachines((page - 1) * 5, mainStates?.building);

    if (machines_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setMachines(machines_?.list ? machines_?.list : []);
    setCountM(machines_?.count ? machines_?.count : 0);
    setLoad(false);
  }, [pageM, updateDataM]);

  return (
    <>
      <AddFloor
        open={addFloors}
        setOpen={setAddFloors}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        parent={mainStates?.building}
      />
      <EditFloors
        floor={editFloors}
        setFloor={setEditFloors}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />
      <AddMachine
        open={addMachine}
        setOpen={setAddMachine}
        setLoad={setLoad}
        update={updateDataM}
        setUpdate={setUpdateDataM}
        parent={mainStates?.building}
        fields={fields}
      />
      <EditMachine
        machine={editMachine}
        setMachine={setEditMachine}
        setLoad={setLoad}
        update={updateDataM}
        setUpdate={setUpdateDataM}
        fields={fields}
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMainPage(1);
          }}
        >
          {mainStates?.estateName ? (
            mainStates?.estateName
          ) : (
            <IntlMessages id="estate.title" />
          )}
        </span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMainPage(2);
          }}
        >
          {` -> ${
            mainStates?.buildingName ? (
              mainStates?.buildingName
            ) : (
              <IntlMessages id="building.title" />
            )
          } ->`}
        </span>
        {
          <>
            <IntlMessages id="floor.title" /> {"/"}
            <IntlMessages id="machine.title" />
          </>
        }
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={12}
              sm={14}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="floor.title" />
              </Text>
            </Col>

            <Col xs={12} sm={10} xl={6}>
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {floors?.length === 0 ? (
            <Empty description="Keine Stockwerke vorhanden!" />
          ) : null}

          {floors?.map((floor) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={14}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditFloors(floor);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {floor?.cfeld2} {floor?.cfeld1}
                  </Text>
                </Col>

                <Col>
                  <MoreActions state={floor} />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>

                <Col>
                  <RightSquareOutlined
                    style={{
                      color: "#038fde",
                      paddingLeft: "40px",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      setMainPage(4);
                      setMainStates({
                        ...mainStates,
                        floor: floor?.stkey,
                        floorName: floor?.cfeld1,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddFloors(!addFloors);
              }}
            >
              <IntlMessages id="floor.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={5}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}

      <div
        style={{
          marginTop: "10px",
        }}
      >
        {!load ? (
          <>
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "20px 0 0 0",
              }}
            >
              <Col
                xs={12}
                sm={14}
                xl={18}
                style={{
                  padding: 0,
                }}
              >
                <Text>
                  {" "}
                  <IntlMessages id="machine.title" />
                </Text>
              </Col>

              <Col xs={12} sm={10} xl={6}>
                <Text>
                  {" "}
                  <IntlMessages id="actions" />
                </Text>
              </Col>
            </Row>

            {machines?.length === 0 ? (
              <Empty description="Keine Anlagen vorhanden!" />
            ) : null}

            {machines?.map((machine) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 30px",
                    margin: "0",
                  }}
                >
                  <Col
                    xs={12}
                    sm={14}
                    xl={18}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditMachine(machine);
                    }}
                  >
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {machine?.cfeld1} {machine?.cfeld3} {machine?.cfeld4}
                    </Text>
                  </Col>

                  <Col>
                    <MoreActions state={machine} />
                    <DownOutlined
                      size="small"
                      style={{
                        color: "#038fde",
                        paddingLeft: "10px",
                        fontSize: "10px",
                      }}
                    />
                  </Col>

                  <Col>
                    <RightSquareOutlined
                      style={{
                        color: "#038fde",
                        paddingLeft: "40px",
                        fontSize: "25px",
                      }}
                      onClick={() => {
                        setMainPage(4);
                        setMainStates({
                          ...mainStates,
                          floor: machine?.stkey,
                          floorName: machine?.cfeld2,
                        });
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Button
                size="small"
                type="primary"
                style={{
                  margin: 0,
                }}
                onClick={() => {
                  setAddMachine(!addMachine);
                }}
              >
                <IntlMessages id="machine.add" />
              </Button>
            </Row>
            <Row
              style={{
                backgroundColor: "#fff",
                padding: "10px 30px 0 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={countM}
                current={pageM}
                defaultPageSize={5}
                style={{
                  margin: 0,
                }}
                onChange={(newpage) => {
                  setPageM(newpage);
                }}
              />
            </Row>
          </>
        ) : null}
      </div>
    </>
  );
}
