import { string_to_double, url } from "../../../../../constants";
import axios from "axios";
import moment from "moment";

export const createEvent = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}eventInsertBaseDataAndRequirements`,
      {
        group: 25,
        stkey: "",
        active: 1,
        stclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editEvent = async (values, key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}eventUpdateBaseData`,
      {
        group: 25,
        stkey: key,
        active: 1,
        stclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getArtists = async (page, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g25",
        stClass: "2",
        stKey: stkey,
        detail: "cldetail_g25",
        detClass: 3,
        type: "none",
        limitFrom: (page - 1) * 10,
        limitTo: "10",
        orderBy: "nfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addArtist = async (values, key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonInsertDetail`,
      {
        detTable__: "cldetail_g25",
        detkey: "",
        active: 1,
        stkey: key,
        stclass: 1,
        detclass: 3,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editArtist = async (values, active) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        ...values,
        active: active ? 0 : 1,
        detTable__: "cldetail_g25",
        stclass: 1,
        detclass: 3,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getEventRequirements = async (stkey, type) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getRequirementsForEventStructured`,
      {
        group: "class_g25",
        stClass: "1",
        stKey: stkey,
        detail: "cldetail_g25",
        detClass: 2,
        type: "none",
        limitFrom: "0",
        limitTo: "100",
        orderBy: "nfeld1,nfeld2",
        cfields:
          type === "outdoor"
            ? []
            : [{ fieldNo: "cfeld1", val: "Infrastruktur", opVal: "!=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getSelectedEventRequirements = async (stkey, type) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getRequirementsForEventStructured`,
      {
        group: "class_g25",
        stClass: "1",
        stKey: stkey,
        detail: "cldetail_g25",
        detClass: 2,
        type: "none",
        limitFrom: "0",
        limitTo: "100",
        orderBy: "nfeld1,nfeld2",
        cfields:
          type === "outdoor"
            ? []
            : [{ fieldNo: "cfeld1", val: "Infrastruktur", opVal: "<>" }],
        nfields: [{ fieldNo: "nfeld3", fromVal: 1, opVal: "=" }],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editMultipleRequirements = async (payload) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}eventUpdateRequirements`,
      [...payload],
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editSingleRequirement = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        detTable__: "cldetail_g25",
        active: 1,
        stclass: 1,
        detclass: 2,
        gisid: " ",
        cfeld5: " ",
        cfeld6: " ",
        cfeld7: " ",
        cfeld8: " ",
        cfeld9: "",
        cfeld10: " ",
        cfeld11: " ",
        cfeld12: " ",
        cfeld13: " ",
        cfeld14: " ",
        cfeld15: " ",
        cfeld16: " ",
        cfeld17: " ",
        cfeld18: " ",
        cfeld19: " ",
        cfeld20: " ",
        nfeld5: 0.0,
        nfeld6: 0.0,
        nfeld7: 0.0,
        nfeld8: 0.0,
        nfeld9: 0.0,
        nfeld10: 0.0,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getComments = async (key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getLogbookForObject`,
      {
        stKey: key,
        limitFrom: "0",
        limitTo: "10",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFiles = async (key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}requestFileList`,
      {
        attribute: attribute,
        objID: key,
        group: "25",
        stClass: "1",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const downloadFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadFileMIMO`,
      {
        imageName: filename,
        objID: key,
        attribute: "contracts",
        stclass: 1,
        group: 25,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const downloadFileforArtist = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadFileMIMO`,
      {
        imageName: filename,
        objID: key,
        detclass: 3,
        stclass: 1,
        group: 25,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadFile = async (file, key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const date = moment(moment()).format("DD.MM.YYYY");

    const time = moment(moment()).format("HH:mm");

    var a = time.substr(0, 2);
    var b = time.substr(3, 2);

    const response = await axios.post(
      `${url}uploadFileWithMIMO`,
      {
        file: file?.file,
        filename: file?.filename,
        attribute: attribute,
        group: 25,
        stclass: 1,
        date: string_to_double(date),
        time: parseFloat(a + b),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: key,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadArtistFile = async (file, key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const date = moment(moment()).format("DD.MM.YYYY");

    const time = moment(moment()).format("HH:mm");

    var a = time.substr(0, 2);
    var b = time.substr(3, 2);

    const response = await axios.post(
      `${url}uploadFileForEventArtist`,
      {
        file: file?.file,
        filename: file?.filename,
        attribute: "artist",
        group: 25,
        stclass: 1,
        detclass: 3,
        date: string_to_double(date),
        time: parseFloat(a + b),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: key,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}removeFile`,
      {
        stkey: key,
        cfeld1: filename,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addComment = async (values, key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}insertLogbookEntryForObject`,
      {
        ...values,
        detTable__: "cldetail_g24",
        detkey: "",
        active: 1,
        stkey: key,
        stclass: 1,
        detclass: 1,
        gisid: null,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
