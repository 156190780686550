import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { getMenu, getStartPage } from "../../constants";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  const history = useHistory();

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const [menu, setMenu] = useState([]);

  useEffect(async () => {
    const menu_ = await getMenu();
    if (menu_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setMenu(menu_ ? menu_ : []);
  }, []);
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup key="main">
              {menu?.map((menuItem) => {
                return menuItem?.length === 1 ? (
                  <Menu.Item
                    key={menuItem[0]?.menukey}
                    // icon={menuItem[0]?.icon}
                  >
                    <Link to={menuItem[0]?.action}>
                      <span>
                        <i className={menuItem[0]?.icon} />
                        <IntlMessages id={menuItem[0]?.caption}></IntlMessages>
                      </span>
                    </Link>
                  </Menu.Item>
                ) : (
                  <SubMenu
                    key={menuItem[0]?.menukey}
                    title={
                      <IntlMessages id={menuItem[0]?.caption}></IntlMessages>
                    }
                  >
                    {menuItem?.slice(1)?.map((item) => {
                      return (
                        <Menu.Item
                          key={item?.menukey}
                          // icon={menuItem[0]?.icon}
                        >
                          <Link to={item?.action}>
                            <span>
                              <i className={item?.icon} />
                              <IntlMessages id={item?.caption}></IntlMessages>
                            </span>
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              })}

              {/* {[
                {
                  manuKey: "Work Orders",
                  action: "/main/workOrders",
                  icon: null,
                },
                {
                  manuKey: "Base Data",
                  action: "/main/workOrders-baseData",
                  icon: null,
                },
              ]?.map((item) => {
                return (
                  <Menu.Item key={item?.menukey}>
                    <Link to={item?.action}>
                      <span>
                        <i className={item?.icon} />
                        <IntlMessages id={item?.caption}></IntlMessages>
                      </span>
                    </Link>
                  </Menu.Item>
                );
              })}

              <SubMenu
                key={"Orders App"}
                title={<IntlMessages id={"Orders App"}></IntlMessages>}
              >
                <Menu.Item key={"WorkOrders"}>
                  <Link to="/main/workOrders">
                    <span>
                      <IntlMessages id={"Work Orders"}></IntlMessages>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key={"BaseData"}>
                  <Link to="/main/workOrders-baseData">
                    <span>
                      <IntlMessages id={"Base Data"}></IntlMessages>
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              */}
              {/*
              <Menu.Item key="tasks">
                <Link to="/main/tasks">
                  <span>
                    <IntlMessages id="Tasks" />
                  </span>
                </Link>
              </Menu.Item>
              
              <SubMenu
                key={"Employee App"}
                title={<IntlMessages id={"Employee App"}></IntlMessages>}
              >
                <Menu.Item key={"employeeOverview"}>
                  <Link to="/main/employee_app/1">
                    <span>
                      <IntlMessages id={"Employee Overview"}></IntlMessages>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key={"employeeGenOverview"}>
                  <Link to="/main/employee_app/2">
                    <span>
                      <IntlMessages id={"General Content"}></IntlMessages>
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              
              <Menu.Item key="serviceArea">
                <Link to="/main/service_areas">
                  <span>
                    <IntlMessages id="Service Areas" />
                  </span>
                </Link>
              </Menu.Item>

              
              <Menu.Item key="eventManagement">
                <Link to="/main/eventManagement/events">
                  <span>
                    <IntlMessages id="Event Management" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="cockpit">
                <Link to="/main/cockpit">
                <i className="icon icon-dasbhoard"/>

                  <span>
                    <IntlMessages id="Cockpit" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="overview">
                <Link to="/main/overview">
                  <span>
                  <i className="icon icon-crm" />
                    <IntlMessages id="menu.overviewindoor" />
                  </span>
                </Link>
              </Menu.Item>

              <SubMenu key="sub2" title={<IntlMessages id="menu.overviewoutdoor" />}>
                <Menu.Item key="ashtrays">
                  <Link to="/main/ashtrays">
                    <span>
                      <IntlMessages id="menu.ashtray" />
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="dog_bag">
                  <Link to="/main/dog-bag">
                    <span><IntlMessages id="menu.dogbag"/></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="stations">
                  <Link to="/main/stations">
                  <span><IntlMessages id="menu.wastestations"/></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="trashtrays">
                  <Link to="/main/trashtrays">
                    <span><IntlMessages id="menu.TrashTrays"/></span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="sub1" title={<IntlMessages id="menu.tools"/>}>
                <Menu.Item key="users">
                 <i className="icon icon-avatar"/>
                  <Link to="/main/user-management">
                    <span>User Management</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="list">
                <i className="icon icon-all-contacts"/>
                  <Link to="/main/list-overviews">
                    <span>List of Values</span>
                  </Link>
                </Menu.Item>
              </SubMenu> 

              <Menu.Item key="trees">
                <Link to="/main/trees">
                  <span>Tree Catastre</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="hydrant">
                <Link to="/main/hydrant">
                  <span>Hydrant</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="automatic_watering">
                <Link to="/main/automatic_watering">
                  <span>Automatic Watering</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="fountain">
                <Link to="/main/fountain">
                  <span>Fountain</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="other_water_connectors">
                <Link to="/main/other_water_connectors">
                  <span>Other Water Connectors</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="pumping_stations">
                <Link to="/main/pumping_stations">
                  <span>Pumping Stations</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="vehicle">
                <Link to="/main/vehicles_overview/1">
                  <span>Vehicle Overview</span>
                </Link>
              </Menu.Item>*/}
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
